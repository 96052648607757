import htmx from 'htmx.org'
import Pocketbase from 'pocketbase'
import Alpine from 'alpinejs'
import immersiveTextComponent from './immersiveText'
import loginComponent from './login'
import { icons, createElement } from 'lucide'

// Add Alpine instance to window object.
window.Alpine = Alpine
/* @ts-ignore */
window.Pocketbase = Pocketbase
/* @ts-ignore */
window.htmx = htmx

// Immersive Text
Alpine.data('immersiveTextComponent', immersiveTextComponent)

// Login
Alpine.data('loginComponent', loginComponent)

Alpine.directive(
  'lucide',
  (el: HTMLElement, { expression }, { effect, evaluateLater }) => {
    effect(() => {
      evaluateLater(expression)((value: any) => {
        Alpine.mutateDom(() => {
          if (value == null) {
            // Allow null or undefined values for dynamic use
            el.innerHTML = ''
          } else if (value in icons) {
            el.innerHTML = ''
            el.appendChild(createElement(icons[value]))
          } else {
            throw new Error(`"${value}" is not a valid Lucide icon name.`)
          }

          el._x_ignoreSelf = true
          Alpine.initTree(el)
          delete el._x_ignoreSelf
        })
      })
    })
  }
)

// Start Alpine.
Alpine.start()
